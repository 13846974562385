import React, { useState } from "react";
import { useRef } from "react";
import { updateExpenseById } from "../../../services/ExpenseService";
import { toast } from "react-toastify";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
registerLocale("pt-BR", ptBR);
setDefaultLocale("pt-BR");
const dayjs = require("dayjs");

export const EditarDespesaModal = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const inputDescription = useRef("");
  const inputValue = useRef("");
  const [createdAt, setCreatedAt] = useState(
    new Date(props.expense.createdAt + " 00:00:00")
  );
  const toggle = () => setIsOpen(!isOpen);
  const expensesTypes = [
    { name: "Variável", value: "VARIABLE" },
    { name: "Fixa", value: "FIXED" },
  ];
  const [selectedTypeExpense, setSelectedTypeExpense] = useState(
    expensesTypes.find((expenseType) => expenseType.name === props.expense.type)
  );
  const [paid, setPaid] = useState(props.expense.paid);
  const [notifyDueDate, setNotifyDueDate] = useState(props.expense.notifyDueDate);

  function handleSubmit(e) {
    e.preventDefault();

    const body = {
      value: parseFloat(inputValue.current.value),
      description: inputDescription.current.value,
      type: selectedTypeExpense.value,
      createdAt: dayjs(createdAt).format("YYYY-MM-DD"),
      paid,
      notifyDueDate
    };

    updateExpense(props.expense.id, body);
  }

  function updateExpense(expenseId, body) {
    updateExpenseById(expenseId, body)
      .then((response) => {
        setIsOpen(false);
        toast.success(response);
        props.listExpenses(false);
      })
      .catch((error) => toast.error(error));
  }

  const formatSelectLabels = (type) => <span>{type.name}</span>;

  return (
    <div>
      <button
        onClick={() => setIsOpen(true)}
        type="button"
        className="btn btn-dark"
      >
        Editar
      </button>

      <Modal
        isOpen={isOpen}
        toggle={() => setIsOpen(false)}
        backdrop="static"
        size="lg"
      >
        <ModalHeader toggle={toggle}>Editar dados da despesa</ModalHeader>

        <form onSubmit={handleSubmit}>
          <ModalBody>
            <div className="row mb-3">
              <div className="col-3 mb-3">
                <label className="form-label bold">Tipo</label>
                <Select
                  onChange={(data) => setSelectedTypeExpense(data)}
                  id="listaTipos"
                  name="tipos"
                  classNamePrefix="select"
                  defaultValue={expensesTypes.find(
                    (expenseType) => expenseType.name === props.expense.type
                  )}
                  options={expensesTypes}
                  className="basic-multi-select"
                  formatOptionLabel={formatSelectLabels}
                  placeholder="Tipo despesa"
                  required
                />
              </div>
              <div className="col-lg-6 mb-3">
                <label className="form-label bold">Descrição</label>
                <input
                  ref={inputDescription}
                  defaultValue={props.expense.description}
                  className="form-control"
                  required
                />
              </div>

              <div className="col-lg-3 col-6">
                <label className="form-label bold">Valor R$</label>
                <input
                  ref={inputValue}
                  defaultValue={props.expense.value}
                  type="number"
                  step={0.01}
                  className="form-control"
                  required
                />
              </div>

              <div className="col-lg-3 col-6">
                <label className="form-label bold">Data vencimento</label>
                <div>
                  <DatePicker
                    className="custom-datepicker form-control"
                    dateFormat="dd/MM/yyyy"
                    locale="pt-BR"
                    selected={createdAt}
                    onChange={(date) => setCreatedAt(date)}
                    todayButton="Hoje"
                    required
                  />
                </div>
              </div>

              <div
                className="col-9 mt-3 mt-lg-0"
                title="Marque sim se deseja receber mensagem 1 dia antes da despesa vencer e tambem no dia de vencimento"
              >
                <label className="form-label bold">Lembrete vencimento</label>

                <div className="align-self-center">
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={notifyDueDate}
                      defaultChecked={notifyDueDate}
                      onChange={() => setNotifyDueDate(!notifyDueDate)}
                    />
                    <label className="form-check-label">
                      {notifyDueDate ? "Sim" : "Não"}
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-12 mt-3">
                <label className="form-label bold">Paga</label>

                <div className="align-self-center">
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="switchPaymentMethod"
                      checked={paid}
                      defaultChecked={paid}
                      onChange={() => setPaid(!paid)}
                    />
                    <label
                      className="form-check-label"
                      for="switchPaymentMethod"
                    >
                      {paid ? "Sim" : "Não"}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>

          <ModalFooter>
            <button type="submit" className="btn btn-dark">
              Salvar
            </button>
            <button
              type="button"
              onClick={() => setIsOpen(false)}
              className="btn btn-danger"
            >
              Cancelar
            </button>
          </ModalFooter>
        </form>
      </Modal>
    </div>
  );
};

export default EditarDespesaModal;
