import React, { useEffect, useRef, useState } from "react";
import Navbar from "../../../Navbar";
import Sidebar from "../../../Sidebar";
import {
  deleteCustomer,
  listAllCustomersPageable,
  listAllCustomersByPage,
  findCustomerByNameOrPhone,
} from "../../../services/CustomerService";
import { CDBTable, CDBTableHeader, CDBSpinner, CDBTableBody } from "cdbreact";
import ClienteForm from "./modal/AdicionarClienteForm";
import EditarClienteModal from "./modal/EditarClienteModal";
import PaginationBootstrap from "../../../components/PaginationBootstrap";
import { toast } from "react-toastify";
import { maskPhone } from "../../../util/StringUtils";
import HistoricoCliente from "./modal/HistoricoCliente";

export const Clientes = () => {
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageInfo, setPageInfo] = useState({});
  const [toggled, setToggled] = useState(false);
  const [customer, setCustomer] = useState();
  const [historyOpen, setHistoryOpen] = useState(false);
  const filterNameOrPhone = useRef("");

  useEffect(() => {
    window.scrollTo(0, 0);
    handleSidebarToggle();
    listCustomers();
  }, []);

  function listCustomers(delay) {
    setIsLoading(true);
    listAllCustomersPageable(delay)
      .then((response) => {
        setCustomers(response.data);
        setPageInfo(response.pageInfo);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }

  function listCustomersByPage(pageNumber) {
    listAllCustomersByPage(pageNumber)
      .then((response) => {
        setCustomers(response.data);
        setPageInfo(response.pageInfo);
      })
      .catch((error) => {});
  }

  function removeCustomer(customerId) {
    const execute = window.confirm(
      "Tem certeza que deseja remover este cliente?"
    );

    if (execute) {
      deleteCustomer(customerId)
        .then((response) => {
          toast.success(response);
          listCustomers(false);
        })
        .catch((error) => {
          console.log(error);
          toast.error("Não foi possivel remover este cliente");
        });
    }
  }

  function handleSidebarToggle() {
    const isMobile = window.innerWidth <= 720;
    if (isMobile) {
      setToggled(true);
    }
  }

  function handleClickHistory(customer) {
    setCustomer(customer);
    setHistoryOpen(true);
  }

  function handleClickClearFilter() {
    listCustomers();
    filterNameOrPhone.current.value = "";
  }

  function findCustomer() {
    setIsLoading(true);
    findCustomerByNameOrPhone(filterNameOrPhone.current.value)
      .then((response) => {
        setCustomers(response.data);
        setPageInfo(response.pageInfo);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }

  return (
    <div className="d-flex">
      <div>
        <Sidebar toggled={toggled} />
      </div>
      <div
        style={{
          flex: "1 1 auto",
          display: "flex",
          flexFlow: "column",
          minHeight: "100vh",
          overflowX: "hidden",
        }}
      >
        <Navbar toggled={toggled} setToggled={setToggled} />
        <div style={{ height: "100%" }}>
          <div
            style={{
              padding: "20px 5%",
            }}
          >
            <div>
              <div className="mt-3">
                <div className="d-flex justify-content-between">
                  <h3>Clientes</h3>
                </div>

                <div className="d-flex mt-3 mb-1">
                  <div>
                    <ClienteForm listCustomers={listCustomers} />

                    {historyOpen && (
                      <HistoricoCliente
                        historyOpen={historyOpen}
                        setHistoryOpen={setHistoryOpen}
                        customer={customer}
                      />
                    )}
                  </div>
                  <div className="vertical-line me-1"></div>
                  <div>
                    <button
                      class="btn"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseExample"
                      aria-expanded="false"
                      aria-controls="collapseExample"
                    >
                      <i className="fa fa-filter me-2"></i>
                      Filtrar
                    </button>
                  </div>
                </div>

                <div className="mt-3 mb-3">
                  <div class="collapse" id="collapseExample">
                    <div class="card card-body">
                      <div>
                        <input
                          ref={filterNameOrPhone}
                          className="form-control"
                          placeholder="Filtrar por nome ou telefone do cliente"
                        />
                      </div>
                      <div className="mt-3">
                        <button
                          className="btn btn-sm btn-dark"
                          onClick={findCustomer}
                          disabled={!filterNameOrPhone}
                        >
                          {" "}
                          <i
                            className="fa fa-search me-2"
                            aria-hidden="true"
                          ></i>
                          Pesquisar
                        </button>

                        <button
                          className="btn mx-3"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseExample"
                          onClick={handleClickClearFilter}
                        >
                          <i className="fa fa-times me-2"></i>
                          Limpar filtros
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {isLoading ? (
                  <div className="d-flex justify-content-center p-3">
                    <CDBSpinner dark />
                  </div>
                ) : (
                  <div>
                    <CDBTable responsiveSm responsiveMd>
                      <CDBTableHeader>
                        <tr>
                          <th>Nome</th>
                          <th>Telefone</th>
                          <th>Notificar</th>
                          <th>Endereço</th>
                          <th>Ações</th>
                        </tr>
                      </CDBTableHeader>
                      <CDBTableBody>
                        {customers ? (
                          customers.map((customer) => (
                            <tr key={customer.id}>
                              <td>{customer.name}</td>
                              <td>{maskPhone(customer.phone.substring(2))}</td>

                              <td>{customer.notify ? "Sim" : "Não"}</td>
                              <td>{customer.address}</td>
                              <td>
                                <div className="d-flex">
                                  <EditarClienteModal
                                    listCustomers={listCustomers}
                                    customer={customer}
                                  />

                                  <button
                                    onClick={() => handleClickHistory(customer)}
                                    type="button"
                                    className="btn btn-dark mx-3"
                                    title="Histórico de serviços feitos para o cliente"
                                  >
                                    <i className="fa fa-history"></i>
                                  </button>

                                  <button
                                    className="btn btn-danger"
                                    onClick={() => removeCustomer(customer.id)}
                                  >
                                    <i className="fa fa-trash" />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <></>
                        )}
                      </CDBTableBody>
                    </CDBTable>
                    <div>
                      {customers.length > 0 ? (
                        <PaginationBootstrap
                          pageInfo={pageInfo}
                          goToPage={listCustomersByPage}
                        />
                      ) : (
                        <>Nenhum cliente cadastrado.</>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Clientes;
