import { toast } from "react-toastify";
import {
  deleteServiceControll,
  nextStepServiceControll,
} from "../../../services/ServiceControllService";
import { useState } from "react";
const dayjs = require("dayjs");

export const CardControlService = (props) => {
  const [requesting, setRequesting] = useState(false);

  function nextStep() {
    const executeAction = window.confirm(
      `Enviar veículo ${props.serviceControll.vehicleDescription} para a proxima etapa ?`
    );

    if (executeAction) {
      setRequesting(true);
      nextStepServiceControll(props.serviceControll.id)
        .then((response) => {
          toast.success(response);
          props.listControllServices(false);
          setRequesting(false);
        })
        .catch((error) => {
          toast.error(
            "Não foi possivel enviar controle de serviço para a proxima etapa"
          );
          setRequesting(false);
        });
    }
  }

  function removeServiceControll() {
    const executeAction = window.confirm(
      `Confirma a exclusão irreversível do controle de serviço do veículo ${props.serviceControll.vehicleDescription}? `
    );

    if (executeAction) {
      deleteServiceControll(props.serviceControll.id)
        .then((response) => {
          toast.success(response);
          props.listControllServices(false);
        })
        .catch((error) => {
          toast.error("Não foi possivel excluir controle de serviço");
        });
    }
  }

  return (
    <div
      className="col-lg-4 mb-3"
      title={props.renderLateTitle(props.serviceControll)}
    >
      <div className="car-service-control">
        <div className="text-uppercase vehicleDescription bold vehicleDesc">
          {props.serviceControll.vehicleDescription.replace("_", " ")}
        </div>
        <div className="license-plate text-uppercase">
          <strong> {props.serviceControll.car.licensePlate}</strong>
        </div>
        <div>
          {props.serviceControll.services
            .map((service) => service.name)
            .join(", ")}
        </div>
        <div className="bold mt-3">Previsão de entrega</div>
        <div className="estimatedDate">
          {dayjs(props.serviceControll.estimatedTime).format(
            "DD/MM/YYYY HH:mm"
          )}
        </div>
        <div className="mt-1" title="Funcionário responsável pelo serviço">
          <i class="fas fa-user-tie"></i> {props.serviceControll.employeeName}
        </div>

        <div className={"mt-1 " + props.renderLateClass(props.serviceControll)}>
          <strong> {props.serviceControll.status}</strong>
        </div>

        <div className="d-flex justify-content-center mt-3">
          <button
            className="btn btn-dark mx-2"
            disabled={requesting}
            onClick={nextStep}
          >
            Proxima etapa
          </button>

          <div className="dropdown align-self-end">
            <button
              className="btn btn-dark dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            ></button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a
                className="dropdown-item cursor"
                onClick={() =>
                  props.handleEditServiceControll(props.serviceControll)
                }
              >
                Editar
              </a>
              <a
                className="dropdown-item cursor"
                onClick={removeServiceControll}
              >
                Excluir
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardControlService;
