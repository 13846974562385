import React, { useEffect, useState } from "react";
import {
  createOrUpdateConfigByCompanyId,
  getLoylatyCofigByCompanyId,
} from "../../../services/LoyaltyService";
import { toast } from "react-toastify";
import { listAllOnlyIdsAndNames } from "../../../services/ServicoService";
import ReactSelect from "react-select";

export const FidelidadeTab = () => {
  const [services, setServices] = useState([]);
  const [loyaltyConfig, setLoyaltyConfig] = useState();
  const [enabledFidelity, setEnableFidelity] = useState(false);
  const [selectedServices, setSelectedServices] = useState([]);
  const [excludedServices, setExcludedServices] = useState();
  const [isEpecificService, setIsEpecificService] = useState(false);

  useEffect(() => {
    listAllOnlyIdsAndNames().then((response) => {
      setServices(response);
    });
    loadLoyaltyConfig();
  }, []);

  useEffect(() => {
    if (loyaltyConfig) {
      setEnableFidelity(loyaltyConfig.enabled);
      setExcludedServices(loyaltyConfig.excludedServices);
    }
  }, [loyaltyConfig]);

  useEffect(() => {
    if (excludedServices) {
      try {
        if (services && loyaltyConfig.excludedServices.length > 0) {
          setSelectedServices(
            loyaltyConfig.excludedServices.map((exs) => ({
              key: exs.id,
              label: services.find((s) => s.id == exs.id).name,
              value: services.find((s) => s.id == exs.id).name,
            }))
          );
          setIsEpecificService(true);
        }
      } catch (e) {
        alert("Exceção na aba fidelidade: " + e);
      }
    }
  }, [excludedServices]);

  function loadLoyaltyConfig() {
    getLoylatyCofigByCompanyId()
      .then((response) => {
        setLoyaltyConfig(response);
      })
      .catch((error) => console.error(error));
  }

  function updateConfig() {
    createOrUpdateConfigByCompanyId({
      id: loyaltyConfig.id,
      enabled: enabledFidelity,
      excludedServices: isEpecificService
        ? selectedServices.map((s) => ({ id: s.key }))
        : [],
    })
      .then((response) => {
        setLoyaltyConfig(response);
        toast.success(`Fidelidade atualizada`);
      })
      .catch((error) =>
        toast.error("Não foi possivel atualizar sua fidelidade")
      );
  }

  return (
    <div className="card p-3 border-top-0">
      <div className="row">
        <div className="col-12">
          <div className="align-self-center">
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                id="switchPaymentMethod"
                checked={enabledFidelity}
                onChange={() => setEnableFidelity(!enabledFidelity)}
              />
              <label className="form-check-label" for="switchPaymentMethod">
                {enabledFidelity ? "Ativado" : "Desativado"}
              </label>
            </div>
          </div>
        </div>

        <div className="col-12 mt-3">
          <label className="form-label bold">A cada</label>

          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="flexRadioDefault2"
              id="flexRadioDefault2"
              checked
            />
            <label class="form-check-label" for="flexRadioDefault2">
              10 serviços
            </label>
          </div>
        </div>

        <div className="col-12 mt-3">
          <label className="form-label bold">Ganha</label>

          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="flexRadioDefault3"
              id="flexRadioDefault3"
              checked
            />
            <label class="form-check-label" for="flexRadioDefault3">
              1
            </label>
          </div>
        </div>

        <div className="col-12 mt-3">
          <label className="form-label bold">
            Serviços incluídos na fidelidade
          </label>

          <div class="form-check">
            <div>
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault4"
                id="flexRadioDefault4"
                checked={!isEpecificService}
                onChange={() => setIsEpecificService(!isEpecificService)}
              />
              <label class="form-check-label" for="flexRadioDefault4">
                Todos
              </label>
            </div>

            <div>
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault5"
                id="flexRadioDefault5"
                checked={isEpecificService}
                onChange={() => setIsEpecificService(!isEpecificService)}
              />
              <label class="form-check-label" for="flexRadioDefault5">
                Todos, menos os selecionados abaixo
              </label>
            </div>
          </div>

          {isEpecificService && (
            <div className="mt-2">
              <ReactSelect
                isClearable
                id="services"
                name="services"
                classNamePrefix="select"
                onChange={(data) => setSelectedServices(data)}
                value={selectedServices}
                isMulti
                options={services.map((service, index) => ({
                  value: service.name,
                  key: service.id,
                  label: service.name,
                }))}
                className="basic-multi-select"
                placeholder="Selecione os serviços que não participam da fidelidade"
                required
              />
            </div>
          )}
        </div>

        <hr className="mt-3" />

        <div>
          <button className="btn btn-dark" onClick={updateConfig}>
            Salvar
          </button>
        </div>
      </div>
    </div>
  );
};

export default FidelidadeTab;
